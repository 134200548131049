<template>
  <div id="accounts-payable-filters">
    <div
      class="d-flex-center justify-content-between gap-2 mb-50"
      :class="isMobileView ? 'flex-wrap': ''"
    >
      <b-row
        class="w-100"
        :class="isMobileView ? 'mx-0': ''"
      >
        <!-- ANCHOR START DATE -->
        <b-col
          cols="12"
          md="3"
          :class="isMobileView ? 'mb-75' : ''"
        >
          <b-form-group
            label-class="h6"
            :label="$t('topup.startDate')"
            label-for="startDate"
          >
            <flat-pickr
              id="startDate"
              :value.sync="fromDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                maxDate: maxStartDate,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:fromDateFilter', $event)"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR END DATE -->
        <b-col
          cols="12"
          md="3"
          :class="isMobileView ? 'mb-75' : ''"
        >
          <b-form-group
            label-class="h6"
            :label="$t('topup.endDate')"
            label-for="endDate"
          >
            <flat-pickr
              id="endDate"
              :value.sync="toDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                minDate: minEndDate,
                maxDate: today,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:toDateFilter', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          :class="isMobileView ? 'mb-75' : ''"
        >
          <!-- ANCHOR AGENCY CODE -->
          <b-form-group
            label-class="h6"
            label-for="agencySelect"
            :label="$t('agency.agencyCode')"
          >
            <SelectChildAgency
              id="agencySelect"
              :agency-select.sync="agencySelect"
              class="w-100"
              style="font-size: 1rem;"
              :placeholder="$t('saleReport.phAgencyCode')"
              @input="$emit('update:agencyCodeListFilter', $event.trim())"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <!-- ANCHOR CUSTOMER CODE -->
          <b-form-group
            label-class="h6"
            label-for="customerCode"
            :label="$t('customer.code')"
          >
            <b-form-input
              id="customerCode"
              :value.sync="customerCodeListFilter"
              :placeholder="$t('customer.placeholderCode')"
              :formatter="upperCaseFormatter"
              @input="$emit('update:customerCodeListFilter', $event.trim())"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- ANCHOR Buttons -->
      <div
        class="d-flex align-items-start gap-1"
        :class="isMobileView ? 'w-100 align-items-center justify-content-center' : ''"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient text-nowrap"
          pill
          :disabled="!fromDateFilter || !toDateFilter"
          @click="$emit('refetch-data')"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('search') }}</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="btn-white text-nowrap"
          pill
          :disabled="isEmptyFilter"
          @click="$emit('clear-filter')"
        >
          <feather-icon
            icon="RefreshCwIcon"
            class="mr-50"
            style="color: cadetblue"
          />
          <span
            class="align-middle text-gradient"
            style=""
          >{{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </div>
    <b-row class="mb-0">
      <!-- <b-col>
        <b-form-group
          :label="$t('accountsPayable.openingBalance')"
          label-for="openingBalance"
          label-class="h6"
        >
          <b-form-input
            id="openingBalance"
            :value="formatCurrency(totalOpeningBalance)"
            disabled
          />
        </b-form-group>
      </b-col> -->
      <b-col>
        <b-form-group
          :label="$t('accountsPayable.closingBalance')"
          label-for="closingBalance"
          label-class="h6"
        >
          <b-form-input
            id="closingBalance"
            :value="formatCurrency(totalClosingBalance)"
            disabled
          />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          :label="$t('accountsPayable.receivables')"
          label-for="receivables"
          label-class="h6"
        >
          <b-form-input
            id="receivables"
            :value="formatCurrency(totalReceivables)"
            disabled
          />
        </b-form-group>
      </b-col> -->
      <b-col>
        <b-form-group
          :label="$t('accountsPayable.tradeCreditors')"
          label-for="tradeCreditors"
          label-class="h6"
        >
          <b-form-input
            id="tradeCreditors"
            :value="formatCurrency(totalTradeCreditor)"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { computed, watch, ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    SelectChildAgency: () => import('@/views/apps/retail/components/SelectChildAgency.vue'),

    flatPickr,
  },
  props: {
    isEmptyFilter: {
      type: Boolean,
      default: false,
    },
    fromDateFilter: {
      type: String,
      default: '',
    },
    toDateFilter: {
      type: String,
      default: '',
    },
    agencyCodeListFilter: {
      type: String,
      default: '',
    },
    customerCodeListFilter: {
      type: String,
      default: '',
    },
    totalOpeningBalance: {
      type: Number,
      default: null,
    },
    totalClosingBalance: {
      type: Number,
      default: null,
    },
    totalTradeCreditor: {
      type: Number,
      default: null,
    },
    totalReceivables: {
      type: Number,
      default: null,
    },
  },

  setup(props, { emit }) {
    const today = convertISODateTime(new Date()).dateFilter
    const maxStartDate = computed(() => (props.toDateFilter ? props.toDateFilter : today))
    const minEndDate = computed(() => (props.fromDateFilter ? props.fromDateFilter : undefined))

    const agencySelect = ref()
    watch(agencySelect, val => {
      emit('update:agencyCodeListFilter', val?.agencyCode || null)
    })
    return {
      Vietnamese,
      formatCurrency,
      maxStartDate,
      minEndDate,
      upperCaseFormatter,
      today,
      agencySelect,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#accounts-payable-filters ::v-deep {
  .form-group {
    margin-bottom: 0;
    label {
      margin-bottom: 2px;
    }
  }
}
</style>
